import React from "react";
import ukraine from "../assets/images/ukraineflag.png";


function TopAppBar(){
    return (
      <nav className="w-full py-4 bg-gradient-to-r from-purple-500 via-red-500 to-blue-900 shadow  navbar-sticky">
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between">
          <nav>
            <ul
              className="flex items-center justify-between font-bold text-sm text-white uppercase no-underline"
            >
              <li>
                <a className="hover:text-gray-200 hover:underline px-4" href="/">Home</a>
              </li>
              <li>
                <div className="">
                  <div className="dropdown-container">
                    <div className="dropdown">
                      <label className="flex cursor-pointer px-0" tabIndex="0"
                        >Categories
                      </label>
                      <div
                        className="dropdown-menu dropdown-menu-bottom-right  w-32  bg-glass  dark:bg-backgroundPrimary"
                      >
                        <a href="/news" className="btn btn-ghost text-sm text-base">News</a>
                        <a href="/computing" className="btn btn-ghost text-sm text-base">Computing</a>
                        <a href="/phones" className="btn btn-ghost text-sm text-base">Phones</a>
                        <a href="/televisions" className="btn btn-ghost text-sm text-base">Televisions</a>
                        <a href="/music" className="btn btn-ghost text-sm text-base">Music</a>
                        <a href="/automotive" className="btn btn-ghost text-sm text-base">Automotive</a>
                         <a href="/programming" className="btn btn-ghost text-sm text-base">Programming</a>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <a className="hover:text-gray-200 hover:underline px-4" href="/about">About</a>
              </li>

            </ul>
          </nav>
  
          <div className="flex items-center font-semibold text-sm text-white uppercase no-underline text-white pr-2">
            <ul>
            <li>
                <img src={ukraine} width={32} height={32} alt="ukraine will win"></img>
              </li>
            </ul>

            {/*<a href="#" className=""><ion-icon name="logo-facebook"></ion-icon></a>&nbsp;
            <a href="#" className=""><ion-icon name="logo-tumblr"></ion-icon></a>*/}
          </div>
        </div>
      </nav>
  
    );
  }


  export default TopAppBar;