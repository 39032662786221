import {React, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";




function NewDisplay({title, date, monthyear, content}){

    useEffect(() => {
      
    }, [])
    return (
        <>
        <br /><br />
        <section className="body-font text-base text-black-500 dark:text-white">
          <div className="container px-5 py-12 mx-auto">
            <h2 className="flex justify-center items-center px-auto font-semibold text-sky-500">{title}</h2> 
            <p  className="flex justify-center items-center px-auto  text-sm">By Narcisse T.</p> 
            <p  className="flex justify-center items-center px-auto  text-sm">Last updated: {date} {monthyear}</p><hr /> <br /> 
              <div className="px-2 py-2">
                <div dangerouslySetInnerHTML={{__html : content} }></div>
              </div>

              
          </div>
      </section> 

        </>

    );
}





    function NewView (){
        let { id } = useParams();

        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [items, setItems] = useState([]);

        useEffect(() => {
                const fileID = parseInt(id);
                const url = `https://goldfish-app-asx3v.ondigitalocean.app/tech/${fileID}`;
                
                fetch(url)
                  .then(res => res.json())
                  .then(
                    (result) => {
                      setIsLoaded(true);
                      setItems(result);
                    },
                    (error) => {
                      setIsLoaded(true);
                      setError(error);
                    }
                  );   
        }, [id])// <-- }, [])  empty array means 'run once'
    

        if (error) {
          return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Loading...</div>;
        } else {
                return(
                    <div  className="z-0">
                      <Helmet>
                        <meta charSet="utf-8" />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={items.Title} />
                        <meta property="og:description" content={items.SmallDescription} />
                      </Helmet>


                        { 
                            <NewDisplay
                            key={items.Id} 
                            title={items.Title}
                            date={items.Date}
                            monthyear={items.MonthAndYear}
                            content={items.LongHtmlDescription}
                            ></NewDisplay>
                        }
                    </div>
            
                );
            }
    }
    
export  default NewView;